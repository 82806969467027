body{
    margin-top:20px;
    background:#dcdcdc;
}
.card-box {
    padding: 20px;
    border-radius: 3px;
    margin-bottom: 30px;
    background-color: #fff;
}
.search-result-box .tab-content {
    padding: 30px 30px 10px 30px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -moz-box-shadow: none
}

.search-result-box .search-item {
    padding-bottom: 20px;
    border-bottom: 1px solid #e3eaef;
    margin-bottom: 20px
}
.text-success {
    color: #0acf97!important;
}
a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
.btn-custom {
    background-color: #02c0ce;
    border-color: #02c0ce;
}

.btn-custom, .btn-danger, .btn-info, .btn-inverse, .btn-pink, .btn-primary, .btn-purple, .btn-success, .btn-warning {
    color: #fff!important;
}

.text-muted {
    --bs-text-opacity: 1;
    color: #3d3a37!important;
}

/* Add this to your CSS file for illustration*/
.gallery-item img {
    width: 100%; /* Make the images take 100% of the container width */
    height: auto; /* Maintain aspect ratio */
    border: 1px solid #ccc; /* Add a border around the images */
    border-radius: 5px; /* Optional: Add border-radius for rounded corners */
    margin-bottom: 10px; /* Optional: Add some space between images */
  }

  .categories-container {
    /* display: grid; */
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); 
    gap: 10px; 
  }
  
  .category-item {
    display: flex;
    align-items: center;
  }
  
  .selected {
    /* Style for selected category, if needed */
    font-weight: bold;
    color: #F74B02; /* Example color for selected category */
  }

  .tag-list {
    display: flex;
  }
  
  .tag {
    display: inline-flex;
    margin-right: 5px; /* Adjust as needed for spacing */
  }
  
  
  