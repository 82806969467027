

  .categories-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px; 
    margin: 0 auto; 
  }
  
  .category-item {
    margin-right: 10px; 
    margin-bottom: 10px; 
  }
  
  .selected {
    color: #F74B02;
  }

  .tag-list {
    display: flex;
    flex-direction: row;
  }
  
  .tag {
    display: flex;
    align-items: center; /* Optional: Align items vertically */
    margin-right: 5px; /* Adjust as needed for spacing */
  }
  
  .tag-list-container {
    display: flex;
    flex-direction: row;
  }


  