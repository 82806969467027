
.category-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    padding: 16px;
  }
  
  .category-item {
    background: #f9f9f9;
    border: 0px solid #ddd;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
  }
  