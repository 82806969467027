

/* Center the review content */
.container-center {
  display: flex;
  justify-content: center;
}

/* Align the content to the left */
.content-left {
  text-align: left;
}
