/* Add custom CSS for tooltip */

.tooltip {
    position: relative;
    display: inline-block;
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    background-color: #F74B02;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px; /* Adjust as needed */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #F74B02 transparent transparent transparent;
  }
  
  /* Show the tooltip text when hovering over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  