/* Pagination styles */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    color: #007bff;
    padding: 8px 12px;
    margin: 0 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .pagination button:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .pagination button.active {
    background-color: #007bff;
    color: #fff;
    cursor: default;
  }
  
  .pagination span {
    padding: 8px 12px;
    margin: 0 4px;
    color: #6c757d;
  }
  
  .pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  