/* TextEditor.css */
.sun-editor table {
  border-collapse: collapse;
  border: 2px solid #0c0c0c;
}

.sun-editor th,
.sun-editor td {
  border: 2px solid #0c0c0c;
}

.sun-editor th {
  border-bottom: 2px solid #0c0c0c; /* Adding bottom border to th elements */
}
.sun-editor tr {
  border: 2px solid #0c0c0c;
}



.sun-editor th {
  border-bottom: 2px solid #0c0c0c; /* Adding bottom border to th elements */
}

.sun-editor td {
  border-right: 2px solid #0c0c0c; /* Adding right border to td elements */
}
