
.gallery-item {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .gallery-item img {
    max-width: 100%;
  }
  
  .gallery-item h4 {
    margin-top: 10px;
    font-size: 18px;
  }
  
  .custom-modal {
    max-width: 80%; /* Adjust the width as needed */
  }

  .custom-nav-button {
    background-color: transparent;
    border: none;
    font-size: 18px;
    color: #333;
    cursor: pointer;
  }