.topping-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px; /* Adjust spacing between columns */
  }
  
  .topping {
    display: flex;
    align-items: center;
  }
  
  /* Optional: You can further style the checkboxes and labels as per your design requirements */
  