.pagination-button {
    /* Add your general button styles here */
    margin: 5px;
    padding: 8px 12px;
    background-color: #ddd;
    border: 1px solid #aaa;
    color: #333;
    cursor: pointer;
  }
  
  .pagination-button.active {
    /* Add your styles for the active button here */
    background-color: #007bff;
    color: #fff;
  }
  